import React from 'react'
import RegistrationFormContainer from "./RegistrationFormContainer";
import {Header} from "../../headers/Header";
import {LoginForm} from "./LoginForm";


export function Login({onSuccess}) {

    return (
        <main>
            <Header title="Deriva / Entrar"
                    description="Entre na sua conta"
            />
            <div className="container text-center margin-top-2x margin-bottom-2x">
                <div className="row">
                    <div className="col-md-6 margin-bottom-4x">
                        <LoginForm onSuccess={onSuccess}/>
                    </div>
                    <div className="col-md-6">
                        <RegistrationFormContainer/>
                    </div>
                </div>
            </div>
        </main>
    );
}