import FacebookLogin from 'react-facebook-login/dist/facebook-login-render-props'
import React, {useState} from "react";
import ButtonLoader from "../util/ButtonLoader";
import SessionAction from "../../actions/SessionAction";
import {useDispatch} from "react-redux";

export function FacebookLoginButton({onError, onSuccess})
{
    const [loading, setLoading] = useState(false);
    const dispatch = useDispatch();

    function handleClick()
    {
        setLoading(true);
    }

    function handleSuccess(response)
    {
        if (response.status === "unknown")
        {
            setLoading(false);
            return;
        }

        setLoading(true);

        dispatch(SessionAction.loginFacebook(response.email, response.name, response.userID, response.signedRequest))
            .then(result => {
                setLoading(false);

                if (result.error)
                {
                    handleError(result.payload.response);
                    return;
                }

                if (onSuccess != null) onSuccess();
            })
            .catch(handleError)
    }


    function handleError(error)
    {
        if (onError != null) onError(error);
    }

    return (
        <FacebookLogin
            appId="3048523315173285"
            autoLoad={false}
            fields="name,email"
            callback={handleSuccess}
            render={props => (
                <button className="facebookLogin" disabled={loading} onClick={() => {
                    handleClick();
                    props.onClick();
                }}>
                    <i className="socicon-facebook"></i>
                    Entrar com Facebook
                    <ButtonLoader loading={loading}/>
                </button>
            )}
        />
    );
}