import {useDispatch} from "react-redux";
import SessionAction from "../../actions/SessionAction";
import React, {useState} from "react";
import {Link} from "react-router-dom";
import {Url} from "../../util/Url";
import {Form} from 'react-final-form'
import Validator from "../../util/validator";
import ButtonLoader from "../util/ButtonLoader";
import {InputField} from "./InputField";
import {FORM_ERROR} from "final-form";
import {FormError} from "./FormError";
import {FacebookLoginButton} from "./FacebookLoginButton";
import {GoogleLoginButton} from "./GoogleLoginButton";
import stylesheet from "./login.css";

const _s = stylesheet;


export function LoginForm({onSuccess})
{
    const [error, setError] = useState(null);
    const dispatch = useDispatch();


    async function handleLogin(values)
    {
        setError(null);

        return dispatch(SessionAction.login(values.email, values.password))
            .then(result => {
                if (result.error)
                {
                    return {[FORM_ERROR]: result.payload.response}
                }

                if (onSuccess != null) onSuccess();
            });
    }


    function handleError(e)
    {
        setError(e);
    }


    return (
        <div>
            <img src="/img/login_01.png" style={{width: "auto"}} alt=""/>
            <h5>Entre na sua conta</h5>

            <div className="row margin-top-1x">
                <div className="col-md-8 col-md-offset-2">
                    <Form onSubmit={handleLogin}
                          render={({handleSubmit, pristine, submitting, submitError}) => (
                              <form id="formLogin" onSubmit={handleSubmit}>

                                  <div className="margin-bottom-1x">
                                      <InputField name="email" autoComplete="username"
                                                  placeholder="E-mail"
                                                  validators={[Validator.Required, Validator.Email]}
                                      />
                                  </div>

                                  <div className="margin-bottom-1x">
                                      <InputField name="password" type="password" autoComplete="current-password"
                                                  placeholder="Senha"
                                                  validators={[Validator.Required, Validator.LengthMin6]}
                                      />
                                  </div>

                                  <FormError submitError={submitError}/>
                                  <FormError submitError={error}/>

                                  <div className="margin-bottom-2x">
                                      <button type="submit" className="btn btn-default btn-block margin-right-none"
                                              style={{width: "100%"}} disabled={pristine || submitting}>
                                          Entrar
                                          <ButtonLoader loading={submitting}/>
                                      </button>
                                  </div>
                              </form>

                          )}/>

                    <div className="margin-top-1x">
                        <FacebookLoginButton onError={handleError} onSuccess={onSuccess}/>
                    </div>

                    <div className="margin-top-1x">
                        <GoogleLoginButton onError={handleError} onSuccess={onSuccess}/>
                    </div>

                    <div className="margin-top-2x text-left">
                        <Link to={Url.Account.ForgotPassword} className="underline-link pull-left">
                            Esqueceu sua senha?
                        </Link>
                    </div>
                    <div style={{clear: "both"}}></div>
                    <div className="margin-top-1x text-left">
                        <Link to={Url.Account.ConfirmationResend} className="underline-link pull-left">
                            Não recebeu o e-mail de confirmação?
                        </Link>
                    </div>

                </div>
            </div>
        </div>
    )

}