
import {GoogleLogin, GoogleOAuthProvider, useGoogleLogin} from '@react-oauth/google';
import React, {useState} from "react";
import ButtonLoader from "../util/ButtonLoader";
import SessionAction from "../../actions/SessionAction";
import {useDispatch} from "react-redux";



function CustomGoogleLogin({onError, onSuccess})
{
    const [loading, setLoading] = useState(false);
    const dispatch = useDispatch();

    const login = useGoogleLogin({
        onSuccess: handleSuccess,
        onError: handleError,
        onNonOAuthError: () => setLoading(false),
    });


    function handleClick()
    {
        setLoading(true);
        login();
    }

    async function handleSuccess(tokenResponse)
    {
        setLoading(true);

        const accessToken = tokenResponse.access_token;

        const response = await fetch("https://www.googleapis.com/oauth2/v3/userinfo", {
            method: 'GET',
            cache: 'default', // *default, no-cache, reload, force-cache, only-if-cached
            headers: {
                'Content-Type': 'application/json',
                'Cache-Control': 'max-age=60',
                'Authorization': `Bearer ${accessToken}`
            },
            referrerPolicy: 'no-referrer',
        })

        const result = await response.json();

        dispatch(SessionAction.loginGoogle(result.email, result.name, result.sub, accessToken))
            .then(result => {
                setLoading(false);

                if (result.error)
                {
                    handleError(result.payload.message);
                    return;
                }

                if (onSuccess != null) onSuccess();
            })
            .catch(handleError)
    }


    function handleError(error)
    {
        setLoading(false);
        if (onError != null) onError(error);
    }


    return (
            <button className="googleLogin" disabled={loading} onClick={() => {
                handleClick();
            }}>
                <img src="https://react-oauth.vercel.app/images/google-logo.png" alt=""/>
                Entrar com Google
                <ButtonLoader loading={loading}/>
            </button>
    )
}

export function GoogleLoginButton({onError, onSuccess})
{
    return (
        <GoogleOAuthProvider clientId="715300324065-5137kb5f46pt9u2qev3240r0rdotsd1n.apps.googleusercontent.com">
            <CustomGoogleLogin onError={onError} onSuccess={onSuccess}/>
        </GoogleOAuthProvider>
    )
}