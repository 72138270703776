import React from 'react';
import {Header} from "../../../headers/Header";
import {LoginForm} from "../../login/LoginForm";


export const PasswordComplete = () => (
    <div className="container text-center margin-top-2x margin-bottom-4x">
        <Header title="Deriva / Senha alterada"
                description="Senha alterada com sucesso"
        />
        <div className="panel no-pointer-events">
            <div className="panel-heading">
                <div className="panel-title"><i className="material-icons check_circle"/>&nbsp;Senha redefinida com sucesso!</div>
            </div>
            <div className="panel-body">
                Sua senha foi redefinida com sucesso. Para entrar na sua conta, utilize o seu e-mail e senha abaixo.
            </div>
        </div>

        <div className="row">
            <div className="col-md-8 col-md-offset-2">
                <LoginForm/>
            </div>
        </div>
    </div>
);