import React from "react";


export function FormError({submitError})
{

    if (submitError == null)
    {
        return null;
    }

    console.log(submitError)

    return (
        <div className="input-error" >
            <i className="material-icons error"/>&nbsp;
            <div className="input-error error-text">
                { submitError._error }
            </div>
        </div>
    );
}