import {Field} from "react-final-form";
import FieldComponent from "../util/FieldComponent";
import React from "react";



export const composeValidators = (...validators) => value =>
    validators.reduce((error, validator) => error || validator(value), undefined)


export function InputField({name, type="text", placeholder, autoComplete, validators})
{
    return (
        <Field name={name} type={type} component={FieldComponent}
               className="form-control"
               placeholder={placeholder} autoComplete={autoComplete}
               validate={composeValidators(...validators)}
        />
    )
}