import {createApiLoginAction, createApiLogoutAction} from "./api/util";

import User from "../models/User";
import SessionActionType from "./SessionActionType";
import UserSelector from "../selectors/UserSelector";
import DerivaCookies from "../util/DerivaCookies";
import {Analytics} from "../analytics/Analytics";


class SessionAction {


    static getCurrentUser() {
        return (dispatch, getState) => {

            let user = UserSelector.currentUser(getState());
            // console.log("getCurrentUser ");
            // console.log("user: " + user);

            if (user != null) {
                return Promise.resolve(user);
            }

            let token = DerivaCookies.get("_token", { path: "/" });
            // console.log("token: " + token);

            if (token != null && token !== "")
            {
                return dispatch(SessionAction.loadCurrentUser());
            }

            return Promise.resolve(null);
        }
    }


    static loadCurrentUser()
    {
        return createApiLoginAction(SessionActionType.LOGIN_PREFIX, 'POST', 'users/login.json', null, User.Schema);
    }


    static login(email, password) {

        Analytics.Login("site");

        let data = {
            user: {
                email,
                password,
                remember_me: false
            }
        };

        return createApiLoginAction(SessionActionType.LOGIN_PREFIX, 'POST', 'users/login.json', data, User.Schema);
    }


    static loginFacebook(email, name, uid, signedRequest) {

        Analytics.Login("site-fb");

        let data = {
            email,
            name,
            uid,
            signedRequest
        };

        return createApiLoginAction(SessionActionType.LOGIN_PREFIX, 'POST', 'users/login/facebook.json', data, User.Schema);
    }

    static loginGoogle(email, name, uid, token) {

        Analytics.Login("site-g");

        let data = {
            email,
            name,
            uid,
            token
        };

        return createApiLoginAction(SessionActionType.LOGIN_PREFIX, 'POST', 'users/login/google.json', data, User.Schema);
    }


    static logout()
    {
        Analytics.Event("logout");

        return createApiLogoutAction(SessionActionType.LOGOUT_PREFIX, 'DELETE', 'users/logout.json');
    }
}


export default SessionAction;